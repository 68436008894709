
  .custom{
    margin: 15px !important;
    padding: 20px !important;

}
.p-column-header-content{
padding:15px;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-size: 0.85rem;
}
.p-column-title{
  margin-right: 45px;
}
.custom .p-datatable tbody > tr > td {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.85rem;
  color: #7b809a;
}
